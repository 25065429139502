<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>Group name *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Group name" 
                rules="required">
                <vs-input 
                  v-model="formData.group_name" 
                  class="w-full" 
                  name="group_name" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel/>
            <FormContent>
              <vs-button 
                class="w-1/3 mr-4" 
                @click="submit(context)">Submit</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import get from 'lodash/get'
import { reactive, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useDatepicker from '@/use/useDatepicker'
import useNotify from '@/use/useNotify'

export default {
  name: 'UserGroupForm',
  components: {
    ValidationObserver,
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)
    const { root: { $store, $router, $vs } } = ctx

    const formData = reactive({
      id: null,
      group_name: '',
    })

    const prepareFormData = (result) => {
      formData.id = result.id
      formData.group_name = result.group_name
    }

    const fetchByRouteId = () => {
      const id = get(ctx.root.$router.currentRoute.params, 'id', null)
      if (id) {
        formData.id = id
        $store
          .dispatch('users/fetchUserGroupById', id)
          .then((result) => {
            prepareFormData(result)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }

    onMounted(() => {
      fetchByRouteId()
    })

    const submit = ({ validate }) => {
      validate().then((isValid) => {
        if (!isValid) return
        $vs.loading()
        $store
          .dispatch(`users/${formData.id ? 'updateUserGroup' : 'createUserGroup'}`, formData)
          .then(() => {
            $router.push({ name: 'user-group' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            console.log('error', error)
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    return {
      formData,
      submit,
      useDatepicker: useDatepicker(),
    }
  },
}
</script>
